<template>
    <div>
      <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
        <div class="col-span-1 flex items-center flex-wrap">
          <h2 class="text-2xl font-bold mr-3">Loanbot Transactions</h2>
        </div>
        <div class="col-span-1">
          <div class="relative">
            <search-form
              v-model="query"
              placeholder="Search for user's name, status, etc."
              @submit="(event) => $refs.table.loadAjaxData()"
            />
          </div>
        </div>
      </div>
      <div class="border border-solid border-blue-200">
        <datatable
          :url="`${this.$baseurl}/admin/personal/cards/loanbot-transactions`"
          :ajax="true"
          :ajaxPagination="true"
          :data="transactions.data"
          :columns="transactions.columns"
          :query="query"
          :on-click="goToUser"
          ref="table"
        />
      </div>
    </div>
  </template>
  <script>
  import moment from 'moment';
  export default {
    data() {
      return {
        query: '',
        transactions: this.$options.resource([], {
          columns: [
            {
              name: 'user_id',
              th: 'User ID',
            },
            {
              name: 'transaction_reference',
              th: 'Transaction Reference',
            },
            {
              name: 'description',
              th: 'Description',
            },
            {
              name: 'amount',
              th: 'Amount',
              render: (transaction, amount) => {
                if (amount) {
                  return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                    amount
                  )}</span>`;
                }
                return '<span class="text-gray-500">₦ 0.00</span>';
              },
            },
            {
              name: 'created_at',
              th: 'Date',
              render: (transaction, date) => {
                return moment(date).format('MMM Do YYYY');
              },
            },
          ],
        }),
      };
    },
    methods: {
        goToUser(row) {
          window.open(
            this.$router.resolve({
            name: 'staff-view',
            params: {
                staffId: row?.user_id,
            },
            }).href,
            '_blank'
        );
        },
    },
  };
  </script>
  